export default {
  'global#home': 'Home',
  'global#maps': 'Mappe',
  'global#works': 'Opere',
  'global#date_human': 'Do MMMM YYYY',
  'global#layout_error_title': 'Spiacenti, si è verificato un errore',
  'global#layout_error_description':
    "Prova a ricaricare la pagina tra qualche istante e se il problema persiste contatta l'amministratore della piattaforma.",
  'global#facet_empty_text': 'No hay artículos disponibles',
  'global#facet_loading_text': 'Cargando...',
  'readmore#label': 'Mostra tutto',
  'search#works_total': '<strong>{ total }</strong> Obras',
  'search#works_total_1': '<strong>{ total }</strong> Obra',
  'search#works_total_0': 'No hay Obras',
  'search#traducciones_total': '<strong>{ total }</strong> Traducciones',
  'search#traducciones_total_1': '<strong>{ total }</strong> Traduccion',
  'search#traducciones_total_0': 'No hay Traducciones',
  'search#books_total': '<strong>{ total }</strong> Libri',
  'search#books_total_1': '<strong>{ total }</strong> Libro',
  'search#books_total_0': 'Nessun Libro',
  'search#witnesses_total': '<strong>{ total }</strong> Testimoni',
  'search#witnesses_total_1': '<strong>{ total }</strong> Testimone',
  'search#witnesses_total_0': 'Nessun Testimone',
  'search#placeholder_query-obra': 'Buscar título obra',
  'search#placeholder_query-traduccion': 'Buscar título traducción',
  'search#placeholder_query-content': 'Cerca nelle opere contenute',
  'search#placeholder_obra': 'Buscar título obra traducida',
  'search#placeholder_traductor': 'Buscar un traductor',
  'search#placeholder_authors': 'Buscar un autor',
  'search#header_obra': 'Obras',
  'search#header_authors': 'Autores',
  'search#header_authors_obra': 'Autor obra traducida',
  'search#header_keywords': 'Keywords',
  'search#header_works': 'Opere',
  'search#header_libraries': 'Biblioteca',
  'search#header_cities': 'Città',
  'search#header_dates': 'Date',
  'search#header_intervalo': 'Intervalo',
  'search#header_fiabilitad': 'Fiabilitad',
  'search#facets_title': 'Filtrar los resultados',
  'search#filters_title': 'Filtri attivi:',
  'search#sort_title': 'Orden',
  'search#sort_score': 'Orden por relevancia',
  'search#sort_asc': 'Orden alfabético (A→Z)',
  'search#sort_desc': 'Orden alfabético (Z→A)',
  'search#fallback_text':
    'Su búsqueda no arrojó resultados. Intente cambiar los parámetros o restablecer la búsqueda haciendo clic en el botón de abajo.',
  'search#fallback_button': 'Restablecer búsqueda',
  'search#ko_text':
    'Oops, abbiamo riscontrato un errore nella ricerca. Prova a cambiare i parametri oppure a resettare la ricerca cliccando sul pulsante sottostante.',
  'search#ko_button': 'Resetta la ricerca',
  'search#description_button': 'Info',
  'search#description_link': 'Ok, ho capito',
  'search#resultsamount': 'Resultados por página',
  'resource#authors': 'di',
  'resource#date': 'pubblicato il',
  'resource#time_to_read': 'lettura di',
  'resource#metadata': 'Metadatos',
  'resource#obradereferencia': 'Obra de referencia',
  'resource#metadata_size': 'Dimensioni',
  'search#header_genres': 'Género',
  'search#header_language': 'Lingua',
  'search#header_lengua': 'Lengua',
  'search#header_epoca': 'Época',
  'search#header_traductor': 'Traductor',
  'search#date': 'data',
  'search#bibliographies_total':
    '<strong>{ total }</strong> riferimenti bibliografici',
  'search#bibliographies_total_1':
    '<strong>{ total }</strong> riferimento bibliografico',
  'search#bibliographies_total_0': 'Nessun riferimento bibliografico',
  'search#title_bibliographies': 'Bibliografia',
  'search#biographies_total': '<strong>{ total }</strong> vite antiche',
  'search#biographies_total_1': '<strong>{ total }</strong> vita antica',
  'search#biographies_total_0': 'Nessuna vita antica',
  'search#title_biographies': 'Vite antiche',
  'search#iconographies_total': '<strong>{ total }</strong> iconografie',
  'search#iconographies_total_1': '<strong>{ total }</strong> icongrafia',
  'search#iconographies_total_0': 'Nessuna iconografia',
  'search#title_iconographies': 'Vite antiche',
  'search#tools_total': '<strong>{ total }</strong> materiali e strumenti',
  'search#tools_total_1': '<strong>{ total }</strong> materiale e strumento',
  'search#tools_total_0': 'Nessun materiale e strumento',
  'search#title_tools': 'Materiali e strumenti',
  'advancedsearch#submit': 'Buscar',
  'advancedsearch#reset': 'Limpiar los campos',
  'advancedsearch#fulltext_text': 'Buscar en TespaTrad (cualquier campo)',
  'advancedsearch#label_obras': 'Obras',
  'advancedsearch#section_textual': 'Cerca nelle Obras',
  'advancedsearch#label_author': 'Autore',
  'advancedsearch#label_genre': 'Genero',
  'advancedsearch#works_total': '<strong>{ total }</strong> resultados',
  'advancedsearch#works_total_1': '<strong>{ total }</strong> resultado',
  'advancedsearch#works_total_0': 'No se encontraron resultados',
  "search#results_number": "Resultados por página",
  "advancedsearch#label_estatus": "Estatus",
  "advancedsearch#section_estatus_info": "AVISO IMPORTANTE. La base de datos se actualiza periódicamente, con nuevas traducciones y con mayores informaciones sobre las que ya están recogidas. Además, la búsqueda avanzada puede realizarse de modo exhaustivo tan solo en los campos del apartado “Datos bibliográficos”, mientras que el apartado “Datos adicionales” está en fase de compilación y de momento restituye referencias parciales.",
  "advancedsearch#section_datos-bibliograficos": "Datos bibliográficos",
  "advancedsearch#section_datos-adicionales": "Datos adicionales",
  "advancedsearch#label_titulo-traduccion": "Título traducción",
  "advancedsearch#label_titulo-obra-traducida": "Título obra traducida",
  "advancedsearch#label_lengua": "Lengua",
  "advancedsearch#label_traductor": "Traductor",
  "advancedsearch#label_autor": "Autor obra traducida",
  "advancedsearch#label_fecha": "Fecha",
  "advancedsearch#label_lugar": "Lugar",
  "advancedsearch#label_editorial": "Editorial",
  "advancedsearch#reedicion": "Traducción reeditada",
  "advancedsearch#edicion-moderna": "Se conoce edición moderna (de traducción antigua)",
  "advancedsearch#label_epoca": "Época",
  "advancedsearch#label_formato": "Formato",
  "advancedsearch#label_forma": "Forma",
  "advancedsearch#label_genero": "Género",
  "advancedsearch#label_modelo-formal": "Modelo formal",
  "advancedsearch#presencia-apartados": "Presencia de apartados",
  "advancedsearch#label_tipologia-traduccion": "Tipología de traducción",
  "advancedsearch#pdf": "PDF disponible",
  "advancedsearch#enlace": "Enlace disponible",
  "advancedsearch#label_traduccion-title": "Título traducción",
  "advancedsearch#label_obra-traducida": "Título obra traducida",
  "advancedsearch#description_datos-adicionales": 'ADVERTENCIA: Este apartado se encuentra en fase de compilación y de momento restituye referencias parciales.'
};
