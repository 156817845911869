import {
  MrSearchConfig,
  MrSearchFacetsConfig,
  MrSearchLayoutInput,
} from '@net7/boilerplate-muruca';

const facets = {
  sections: [
    {
      id: 'section-query',
      inputs: [
        {
          id: 'query',
          type: 'text',
          queryParam: true,
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query',
            placeholder: 'search#placeholder_query-traduccion',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
      ],
    },
    {
      id: 'section-obra',
      header: {
        id: 'header-obra',
        data: {
          text: 'search#header_obra',
          additionalText: null,
        },
      },
      inputs: [
        {
          type: 'text',
          id: 'query-obra',
          target: 'obra',
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query-obra',
            placeholder: 'search#placeholder_obra',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
        {
          id: 'obra',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-epoca',
      header: {
        id: 'header-epoca',
        data: {
          text: 'search#header_epoca',
          additionalText: null,
        },
      },
      inputs: [
        {
          id: 'epoca',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-lengua',
      header: {
        id: 'header-lengua',
        data: {
          text: 'search#header_lengua',
          additionalText: null
        }
      },
      inputs: [{
        id: 'lengua',
        type: 'link',
        limit: 50,
        queryParam: true,
        schema: {
          valueType: 'string',
          multiple: true
        },
        data: {
          links: []
        }
      }]
    }, 
    {
      id: 'section-traductor',
      header: {
        id: 'header-traductor',
        data: {
          text: 'search#header_traductor',
          additionalText: null,
        },
      },
      inputs: [
        {
          type: 'text',
          id: 'query-traductor',
          target: 'traductor',
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query-traductor',
            placeholder: 'search#placeholder_traductor',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
        {
          id: 'traductor',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
    {
      id: 'section-authors',
      header: {
        id: 'header-authors',
        data: {
          text: 'search#header_authors_obra',
          additionalText: null,
        },
      },
      inputs: [
        {
          type: 'text',
          id: 'query-authors',
          target: 'authors',
          delay: 500,
          schema: {
            valueType: 'string',
          },
          data: {
            id: 'query-authors',
            placeholder: 'search#placeholder_authors',
            icon: 'n7-icon-search',
            inputPayload: 'search-input',
            enterPayload: 'search-enter',
            iconPayload: 'search-icon',
          },
        },
        {
          id: 'authors',
          type: 'link',
          limit: 50,
          queryParam: true,
          schema: {
            valueType: 'string',
            multiple: true,
          },
          data: {
            links: [],
          },
        },
      ],
    },
  ],
  classes: 'facets-wrapper',
} as MrSearchFacetsConfig;

const layoutInputs = ['page', 'limit', 'sort'].map((id) => ({
  id,
  queryParam: true,
  schema: {
    valueType: id === 'sort' ? 'string' : 'number',
  },
})) as MrSearchLayoutInput[];

const request = {
  results: {
    id: 'search',
    delay: 500,
  },
  facets: {
    id: 'facets',
  },
  provider: 'rest',
  delay: 500,
};

export default { request, facets, layoutInputs } as MrSearchConfig;
