import { ConfigMurucaAdvancedSearchLayout } from '@net7/boilerplate-muruca';

export default {
  title: 'Búsqueda avanzada',
  resultsUrl: { it: '/risultati' },
  hasDynamicOptions: true,
  formConfig: {
    submitButton: {
      label: 'advancedsearch#submit',
    },
    resetButton: {
      label: 'advancedsearch#reset',
    },
    groups: [
      {
        id: 'group-global-search',
        sections: ['section-global'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_global',
          isOpen: true,
          showHeader: false,
        },
      },
      {
        id: 'group-datos-bibliograficos',
        sections: ['section-datos-bibliograficos'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_datos-bibliograficos',
          isOpen: false,
          showHeader: true,
        },
      },
      {
        id: 'group-datos-adicionales',
        sections: ['section-datos-adicionales'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_datos-adicionales',
          isOpen: false,
          showHeader: true,
        },
      },
    ],
    sections: [
      {
        id: 'section-global',
        title: '',
        inputs: [
          {
            id: 'query-fulltext',
            type: 'text',
            data: {
              id: 'query-fulltext',
              label: 'advancedsearch#fulltext_text',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          // {
          //   id: 'traduccion_estatus',
          //   type: 'select',
          //   data: {
          //     id: 'traduccion_estatus',
          //     label: 'advancedsearch#label_estatus',
          //     options: [] 
          //   },
          //   info: 'advancedsearch#section_estatus_info',
          //   state: {
          //     value: '',
          //     disabled: false,
          //     hidden: false,
          //   },
          // },
        ],
      },
      {
        id: 'section-datos-bibliograficos',
        title: '',
        inputs: [
          {
            id: 'query-traduccion-title',
            type: 'text',
            data: {
              id: 'query-traduccion-title',
              label: 'advancedsearch#label_traduccion-title',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-obra-traducida',
            type: 'text',
            data: {
              id: 'query-obra-traducida',
              label: 'advancedsearch#label_obra-traducida',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'traduccion_lengua',
            type: 'select',
            data: {
              id: 'traduccion_lengua',
              label: 'advancedsearch#label_lengua',
              options: []
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'traduccion_traductor',
            type: 'select',
            data: {
              id: 'traduccion_traductor',
              label: 'advancedsearch#label_traductor',
              options: []
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          { // DA CONTROLLARE
            id: 'obra_autor',
            type: 'select',
            data: {
              id: 'obra_autor',
              label: 'advancedsearch#label_autor',
              options: []
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-fecha',
            type: 'text',
            data: {
              id: 'query-fecha',
              label: 'advancedsearch#label_fecha',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-lugar',
            type: 'text',
            data: {
              id: 'query-lugar',
              label: 'advancedsearch#label_lugar',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-editorial',
            type: 'text',
            data: {
              id: 'query-editorial',
              label: 'advancedsearch#label_editorial',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-reedicion',
            type: 'checkbox',
            label: '',
            info: '',
            state: {
              value: [],
            },
            data: {
              id: 'query-reedicion',
              checkboxes: [
                {
                  label: 'advancedsearch#reedicion',
                  payload: '*',
                  checked: false,
                },
              ],
            },
          },
          {
            id: 'query-edicion-moderna',
            type: 'checkbox',
            label: '',
            info: '',
            state: {
              value: [],
            },
            data: {
              id: 'query-edicion-moderna',
              checkboxes: [
                {
                  label: 'advancedsearch#edicion-moderna',
                  payload: '*',
                  checked: false,
                },
              ],
            },
          },
          {
            id: 'traduccion_epoca',
            type: 'select',
            data: {
              id: 'traduccion_epoca',
              label: 'advancedsearch#label_epoca',
              options: []
              
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'traduccion_formato',
            type: 'select',
            data: {
              id: 'traduccion_formato',
              label: 'advancedsearch#label_formato',
              options: []
              
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
      {
        id: 'section-datos-adicionales',
        description: 'advancedsearch#description_datos-adicionales',
        inputs: [
          {
            id: 'traduccion_forma',
            type: 'select',
            data: {
              id: 'traduccion_forma',
              label: 'advancedsearch#label_forma',
              options: []
              
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'traduccion_genero',
            type: 'select',
            data: {
              id: 'traduccion_genero',
              label: 'advancedsearch#label_genero',
              options: []
              
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'traduccion_modeloformal',
            type: 'select',
            data: {
              id: 'traduccion_modeloformal',
              label: 'advancedsearch#label_modelo-formal',
              options: []
              
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-presencia-apartados',
            type: 'checkbox',
            label: '',
            info: '',
            state: {
              value: [],
            },
            data: {
              id: 'query-presencia-apartados',
              checkboxes: [
                {
                  label: 'advancedsearch#presencia-apartados',
                  payload: 'true',
                  checked: false,
                },
              ],
            },
          },
          {
            id: 'traduccion_tipologiatraduccion',
            type: 'select',
            data: {
              id: 'traduccion_tipologiatraduccion',
              label: 'advancedsearch#label_tipologia-traduccion',
              options: []
              
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-pdf',
            type: 'checkbox',
            label: '',
            info: '',
            state: {
              value: [],
            },
            data: {
              id: 'query-pdf',
              checkboxes: [
                {
                  label: 'advancedsearch#pdf',
                  payload: '*',
                  checked: false,
                },
              ],
            },
          },
          {
            id: 'query-enlace',
            type: 'checkbox',
            label: '',
            info: '',
            state: {
              value: [],
            },
            data: {
              id: 'query-enlace',
              checkboxes: [
                {
                  label: 'advancedsearch#enlace',
                  payload: '*',
                  checked: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
} as ConfigMurucaAdvancedSearchLayout;
